<script setup lang="ts">

    /**
     * Переменная для контроля состояния диалога (открыт/закрыт).
     * @type {Ref<boolean>}
     */
    const isDialogOpen = ref<boolean>(false);

    /**
     * Переменная для отслеживания процесса загрузки файла.
     * @type {Ref<boolean>}
     */
    const isLoading = ref(true);

    /**
     * Переменная для хранения содержимого текстового файла.
     * @type {Ref<string>}
     */
    const fileContent = ref('');

    /**
     * Переменная для проверки, загружен ли PDF-файл.
     * @type {Ref<boolean>}
     */
    const isPdfLoaded = ref(false);

    /**
     * Входные параметры (props).
     * @property {string} fileUrl - Путь к текстовому или PDF файлу.
     * @property {string} fileType - Тип файла ('pdf' или 'txt').
     * @property {string} dialogTitle - Заголовок для диалога.
     */
    const props = defineProps({
        fileUrl: { type: String, required: true, default: "_blank" },
        fileType: { type: String, required: true, default: "txt" },
        dialogTitle: { type: String, default: '' }
    });

    /**
     * Функция для загрузки и отображения текстового файла.
     * Загружает файл с указанного URL и сохраняет содержимое в переменную fileContent.
     * В случае ошибки выводит сообщение об ошибке.
     */
    const loadTextFile = async () => {
        try {
            const response = await fetch(props.fileUrl);
            fileContent.value = await response.text();
        } catch (error) {
            console.error('Не удалось загрузить текстовый файл:', error);
            fileContent.value = 'Ошибка при загрузке файла.';
        }
    };

    /**
     * Функция для открытия диалога и загрузки файла.
     * В зависимости от типа файла загружает текстовый или PDF-файл, затем обновляет статус загрузки.
     */
    const onOpenDialog = () : void => {
        isDialogOpen.value = true;
        if (props.fileType === 'txt') {
            loadTextFile();
        }
        isLoading.value = false;
    }

    /**
     * Функция для закрытия диалога.
     * Закрывает диалог, устанавливая isDialogOpen в false.
     */
    const onCancel = () : void => {
        isDialogOpen.value = false;
    }

    /**
     * Наблюдатель за изменением типа файла (props.fileType).
     * В зависимости от типа файла загружает текстовый или PDF-файл.
     */
    watch(() => props.fileType, () => {
        if (isDialogOpen.value) {
            if (props.fileType === 'txt') {
                debugger;
                loadTextFile();
            }
        }
    });
</script>

<template>
    <div class="action">
        <a class="clickable" @click="onOpenDialog">
            {{ dialogTitle }}
        </a>
    </div>
    <shared-base-dialog
        v-model="isDialogOpen"
        @closeDialog="onCancel"
        :title="dialogTitle"
        :show-button="true"
        :max-width="800"
        :max-height="800">
        <template #content>
            <div class="plain-text-container">
                <div v-html="fileContent" class="file-content"></div>
            </div>
        </template>
    </shared-base-dialog>
</template>

<style scoped lang="scss">
    div a.clickable {
        cursor: pointer;
    }

    .v-card-item .v-card-title {
        padding-bottom: 0;
        padding-top: 20px;
    }

    .action {
        display: flex;
        justify-content: flex-end;
    }

    .plain-text-container {
        max-height: 500px;
        overflow-y: auto;
        background-color: $info-href-text-container;
        font-size: 14px;
    }

    .file-content {
        border-top: 1px solid $info-href-file-content;
        border-bottom: 1px solid $info-href-file-content;
    }
</style>
