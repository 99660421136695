<script setup lang="ts">
    const config = useRuntimeConfig();
    const personalDataFileUrl = config.public.personalDataFileUrl;
    const cookiePolicyUrl = config.public.cookiePolicyUrl;
</script>

<template>
    <div class="footer">
        <div class="content">
            <div class="left">
                <div class="policy">
                    <div>
                        <a href="https://kortros.ru/politika-obrabotki-personalnyh-dannyh.pdf" target="_blank">{{ $t('forms.shared.phrases.personalPolicy') }}</a>
                    </div>
                    <div>
                        <shared-common-info-href
                            :file-type="'txt'"
                            :dialog-title="$t('forms.shared.phrases.cookiePolicy')"
                            :file-url="cookiePolicyUrl">
                        </shared-common-info-href>
                    </div>
                    <div>
                        <a :href="personalDataFileUrl" target="_blank">{{ $t('forms.shared.phrases.personalData') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .footer {
        display: flex;
        width: 100%;

        .content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            width: 100%;

            color: var(--Color-Zink-400, $main-text-color);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.13px;
            gap: 16px;

            .left {
                display: flex;
                align-items: center;
                gap: 16px;

                .policy {
                    display: flex;
                    gap: 16px;

                    @include responsive( 'mobile' ) {
                        flex-direction: column;
                        gap: var(--Padding-pa-8, 32px);
                        padding: 0px 20px;
                        text-align: center;
                    }
                }
            }

            @include responsive( 'mobile' ) {
                flex-direction: column;
                gap: var(--Padding-pa-8, 32px);
                padding: 0px 20px;
                text-align: center;
            }
        }
    }
</style>
